<template>
    <div class="likes-list">
        <div class="list-body">
            <h5 class="text-white">{{ $t('Liked this post') }}</h5>
            <div v-for="user in likedUsers" :key="user" class="user-liked text-white">{{ user }}</div>
            <div v-if="showMoreActive" class="text-white cursor-pointer">{{ `and ${likes.amount - this.likedUsers.length} others` }}</div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    likes: {
      type: Object,
      reqaired: true,
    },
  },
  computed: {
    likedUsers() {
      if (this.likes.amount > 9) {
        return this.likes.users.slice(0, 9)
      }
      return this.likes.users
    },
    showMoreActive() {
      return this.likes.amount > this.likedUsers.length
    },
  },
}
</script>
