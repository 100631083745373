<template>
  <div class="w-100 comments-list-body">
    <img
      :src="verticalLinePostImg"
      alt="vertical line"
      class="w-100"
    >
    <b-form-group
      label-for="input-comment"
      :description="$t('Press enter to publish')"
      class="mb-0"
    >
      <b-form-input
        id="input-comment"
        v-model="comment"
        :placeholder="$t('Enter your comment')"
        size="lg"
        @keyup.enter="sendComment"
        @click.stop="checkHPR"
      />
    </b-form-group>
    <div class="comments-list">
      <commentator
        v-for="comment in comments"
        :key="comment.slug"
        :comment="comment"
        :represent-a-company="representACompany"
      />
    </div>
    <NoHPRConfirmedMessage :id="`comments-${id}`" />
  </div>
</template>
<script>
import verticalLinePostImg from '@/assets/images/pages/profile-company/vertical-line-post.svg'
import { BFormInput, BFormGroup } from 'bootstrap-vue'
import Commentator from '@/components/profile-company/Post/Commentator.vue'
import NoHPRConfirmedMessage from '@/components/modal/NoHPRComfirmedMessage.vue'

export default {
  components: {
    BFormInput,
    BFormGroup,
    Commentator,
    NoHPRConfirmedMessage,
  },
  props: {
    id: {
      type: String,
    },
    hasConfirmedHPR: {
      type: Boolean,
      default: true,
    },
    representACompany: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      verticalLinePostImg,
      comment: '',
    }
  },
  computed: {
    comments() {
      return this.$store.getters['postComments/postsComments'](this.id)
    },
  },
  created() {
    this.fetchComments()
  },
  methods: {
    showNoHPRConfirmedMessage() {
      EventBus.$emit(`showNoHPRConfirmedMessage-comments-${this.id}`)
    },
    checkHPR() {
      if (!this.hasConfirmedHPR) {
        this.showNoHPRConfirmedMessage()
      }
    },
    async sendComment() {
      if (!this.hasConfirmedHPR) {
        return
      }
      if (this.comment.length) {
        await this.$store.dispatch('postComments/addCommentToPostById', {
          id: this.id,
          text: this.comment,
        })
        this.comment = ''
        this.$emit('update-post')
      }
    },
    fetchComments() {
      this.$store.dispatch('postComments/fetchPostCommentsById', this.id)
    },
  },
}
</script>
