<template>
  <div>
    <div v-if="!showSeeMore">
        <span v-html="content"></span>
    </div>
    <div v-else>
        <span v-if="isExpanded">
            <span v-html="content"></span>
            <span @click="isExpanded = !isExpanded" class="read-more-link"> See less</span>
        </span>
        <span v-else>
            <span v-html="removeTags(shortContent)"></span>
            <span @click="isExpanded = !isExpanded" class="read-more-link">See more</span>
        </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      required: true,
    },
    maxLength: {
      type: Number,
      default: 400,
    },
  },
  data() {
    return {
      isExpanded: false,
    }
  },
  computed: {
    shortContent() {
      return `${this.removeTags(this.content).slice(0, this.maxLength)}...`
    },
    showSeeMore() {
      return this.removeTags(this.content).length > this.maxLength
    },
    cont1() {
      return this.removeTags(this.content)
    },
  },
  methods: {
    removeTags(str) {
      return str.replace(/<((?!a\b)[^>])+>/gi, '')
    },
  },
}
</script>

<style>
.read-more-link {
  color: #2986e8;
  cursor: pointer;
  display: inline-block;
}

</style>
