<template>
  <div class="wrapper-post-report">
    <b-modal
      id="post-manage"
      v-model="isShowModal"
      no-enforce-focus
      hide-footer
      centered
      @hide="$emit('hide')"
    >
      <div class="post-report-form">
        <div class="report-title">
          <span>Report post</span>
        </div>

        <div class="report-description">
          <span>Please add the reason why you want to report the post from {{ post.owner.name }}.</span>
        </div>

        <validation-observer ref="reportPost">
          <validation-provider
            #default="{ errors }"
            name="report"
            rules="required"
          >
            <b-form-textarea
              v-model="reportText"
              class="bg-light"
              placeholder="Write here..."
              rows="3"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </validation-observer>

        <div class="report-actions">
          <b-button
            class="report-actions__cancel"
            variant="outline-primary"
            @click="isShowModal = false"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            @click="report"
          >
            Send report
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BModal,
  BFormTextarea,
  BButton,
} from 'bootstrap-vue'
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BModal,
    BFormTextarea,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isShowModal: true,
      reportText: '',
    }
  },
  methods: {
    async report() {
      const isValid = await this.$refs.reportPost.validate()

      if (isValid) {
        try {
          const res = await axiosIns.post(`/report/post/${this.post.slug}`, {
            text: this.reportText,
          })

          if (res.status === 200) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Report sent!',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })

            this.reportText = ''
            this.isShowModal = false
          }
        } catch (e) {
          this.$store.dispatch('errorHandler', e, { root: true })
          throw e
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .post-report-form {
    .report-title {
      font-family: 'Proxima Nova';
      font-style: normal;
      color: #0F2F50;
      font-size: 20px;
      font-weight: 700;
      text-align: center;
    }

    .report-description {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #0F2F50;
      text-align: center;
      padding-top: 15px;
      padding-bottom: 15px;
    }

    .report-actions {
      padding-top: 20px;
      text-align: center;

      &__cancel {
        margin-right: 10px;
      }
    }
  }
</style>
