<template>
  <b-modal
    :id="`no-HPR-confirmed-modal-${id}`"
    centered
    hide-footer
    hide-header
    body-class="p-0"
  >
    <div class="d-flex w-100 text-center text-black p-2">
        <h4 class="w-100">{{ $t('This feature is not available for users with an unverified HPR number') }}</h4>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BModal,
  },
  props: {
    id: {
      type: String,
    },
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  created() {
    EventBus.$on(`showNoHPRConfirmedMessage-${this.id}`, () => {
      this.$root.$emit('bv::show::modal', `no-HPR-confirmed-modal-${this.id}`, '#focusThisOnClose')
    })
  },
  methods: {
    closeModal() {
      this.$root.$emit('bv::hide::modal', `no-HPR-confirmed-modal-${this.id}`, '#focusThisOnClose')
    },
  },
}
</script>
