<template>
  <div class="e-post">
    <div class="e-post-header">
      <div class="e-post-author">
        <img
          class="avatar-logo"
          alt="Logo post placeholder"
          :src="getLogo"
          @click="goToOwnerPage"
        >
        <a
          class="e-post-author__title"
          href="#"
          @click="goToOwnerPage"
        >
          {{ item.owner.name }}
        </a>
        <img
          :src="verticalSmallLine"
          alt="Vertical line | small"
          class="e-post-author__vertical-line"
        >
        <span>{{ item.date_created | formatDate }}</span>
        <span
          v-if="item.type"
          :class="['post-type', postTypes[item.type].style]"
        >{{ $t(postTypes[item.type].title) }}</span>

      </div>
      <div class="e-post-author-right">
        <div
          v-if="!isOwner || !canInteract"
          class="post-follow"
        >
          <b-button
            variant="primary"
            size="sm"
            :disabled="representACompany"
            @click="followUserOrCompany"
          >
            {{ $t(item.owner.amIFollowing && !representACompany ? 'Unfollow' : 'Follow') }}
          </b-button>
        </div>
        <div
          v-if="isOwner && canInteract"
          class="e-post-action"
        >
          <b-dropdown
            size="sm"
            variant="link"
            toggle-class="text-decoration-none p-0"
            no-caret
          >
            <template #button-content>
              <img
                :src="actionImg"
                alt="action"
                class="p-0"
              >
            </template>
            <b-dropdown-item
              href="#"
              @click="$emit('edit-post', item)"
            >
              <img
                :src="editImg"
                alt="edit"
              >
              Edit
            </b-dropdown-item>
            <b-dropdown-item
              v-if="representACompany"
              @click="archivePost"
            >
              <img
                :src="archiveImg"
                alt="archive"
              >
              Archive
            </b-dropdown-item>
            <b-dropdown-item
              @click="deletePost"
            >
              <img
                :src="deleteImg"
                alt="delete"
              >
              Delete
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div
          v-else
          class="post-report"
        >
          <b-dropdown
            size="sm"
            variant="link"
            toggle-class="text-decoration-none p-0"
            no-caret
          >
            <template #button-content>
              <img
                :src="actionImg"
                alt="action"
                class="p-0"
              >
            </template>
            <b-dropdown-item
              href="#"
              @click="showReportModal"
            >
              Report
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>

    <div class="e-post-content">
      <div class="e-post-content__title">
        {{ item.title }}
      </div>
      <div
        class="e-post-content__description"
      >
        <post-description
          :content="item.text"
          :max-length="400"
        />
      </div>
      <div
        v-if="item.image"
        class="e-post-content__image"
      >
        <img
          :src="item.image"
          alt="Placeholder post image"
        >
      </div>

      <div class="e-post-content__footer">
        <div class="e-post-content__footer_row1">
          <div class="liked-block">
            <img
              :src="likedPeopleImg"
              alt="like"
            >
            <span>{{ item.likes.amount }}</span>
            <likes-list
              v-if="item.likes.amount"
              :likes="item.likes"
            />
          </div>
          <div
            v-if="isCommentingAllowed"
            class="cursor-pointer"
          >
            <span @click="openComments">{{ `${item.comments_count} ${$t('comments')}` }}</span>
          </div>
        </div>
        <img
          :src="verticalLinePostImg"
          alt="vertical line"
          class="e-post-content__vertical-line"
        >
        <div class="e-post-content__footer_row2">
          <div @click="like">
            <img
              v-if="!isViewer"
              :src="likedCurrentUser ? likedImg : likeImg"
              alt="like"
            >
            <img
              v-else
              :src="likeDisabledImg"
              alt="like"
            >
            <span :class="{ liked: likedCurrentUser, 'text-muted': isViewer }"> Like </span>
          </div>
          <div @click="openComments">
            <img
              v-if="item.isCommentingAllowed"
              :src="commentImg"
              alt="comment"
            >
            <img
              v-else
              :src="commentImgDisabled"
              alt="comment"
            >
            <span :class="{'text-muted': !isCommentingAllowed}">{{ $t('Comment') }}</span>
          </div>
          <div v-if="false">
            <img
              :src="shareImg"
              alt="share"
            >
            <span>Share</span>
          </div>
          <div @click="followPost">
            <img
              v-if="!representACompany"
              :src="item.amIFollowing ? followedImg : followImg"
              alt="follow"
            >
            <img
              v-else
              :src="followImgDisabled"
              alt="follow"
            >
            <span
              :class="{
                'followed': item.amIFollowing && !representACompany,
                'text-muted': representACompany,
              }"
            >
              {{ $t('Follow post') }}
            </span>
          </div>
        </div>
        <div
          v-if="!isCommentingAllowed && isOwner && representCompanyAdminOrEditor"
          class="disabled-comenting-info d-flex align-items-center mt-1"
        >
          <img
            :src="infoCircleIcon"
            alt="like"
          >
          <h5 class="mb-0">
            Commenting posts is disabled for other users. You can change it in <router-link :to="`/profile_settings/${item.owner.slug}`">
              Settings
            </router-link>.
          </h5>
        </div>
      </div>
    </div>
    <comments
      v-if="areCommentsOpen"
      :id="item.slug"
      :has-confirmed-h-p-r="canInteract || representCompanyAdminOrEditor"
      :represent-a-company="representACompany"
      @update-post="$emit('update-post', item.slug)"
    />
    <PostReport
      v-if="showModal"
      :post="item"
      @hide="showModal = false"
    />
    <NoHPRConfirmedMessage :id="`post-${item.slug}`" />
  </div>
</template>

<script>
import avatarPlaceholder from '@/assets/images/pages/profile/user-default-avatar.svg'
import verticalSmallLine from '@/assets/images/pages/profile-company/vertical-small-line.svg'
import likeImg from '@/assets/images/pages/profile-company/like.svg'
import likeDisabledImg from '@/assets/images/pages/profile-company/like-disabled.svg'
import commentImg from '@/assets/images/pages/profile-company/comment.svg'
import commentImgDisabled from '@/assets/images/pages/profile-company/comment-disabled.svg'
import followImg from '@/assets/images/pages/profile-company/follow.svg'
import followImgDisabled from '@/assets/images/pages/profile-company/follow-disabled.svg'
import followedImg from '@/assets/images/pages/followed.svg'
import shareImg from '@/assets/images/pages/profile-company/share.svg'
import actionImg from '@/assets/images/pages/profile-company/action.svg'
import infoCircleIcon from '@/assets/images/pages/profile-company/info-circle-icon.svg'
import verticalLinePostImg from '@/assets/images/pages/profile-company/vertical-line-post.svg'
import likedPeopleImg from '@/assets/images/pages/profile-company/liked-people.svg'
import likedImg from '@/assets/images/pages/profile-company/liked.svg'
import reportImg from '@/assets/images/pages/export.svg'
import editImg from '@/assets/images/pages/profile-company/edit.svg'
import deleteImg from '@/assets/images/pages/profile-company/delete.svg'
import archiveImg from '@/assets/images/pages/profile-company/archive.svg'
import {
  BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'
import PostDescription from '@/components/profile-company/Post/PostDescription.vue'

import Comments from '@/components/profile-company/Post/Comments.vue'
import NoHPRConfirmedMessage from '@/components/modal/NoHPRComfirmedMessage.vue'

import moment from 'moment'
import axiosIns from '@/libs/axios'
import { mapGetters } from 'vuex'
import PostReport from '@/components/modal/post-report'
import LikesList from '@/components/profile-company/Post/LikesList.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'Post',
  components: {
    BDropdown,
    BDropdownItem,
    Comments,
    PostReport,
    LikesList,
    BButton,
    NoHPRConfirmedMessage,
    PostDescription,
  },
  filters: {
    formatDate(date) {
      return moment(date)
        .format('DD MMMM YYYY, hh:mm')
    },
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      avatarPlaceholder,
      verticalSmallLine,
      likeImg,
      likeDisabledImg,
      commentImg,
      commentImgDisabled,
      shareImg,
      actionImg,
      verticalLinePostImg,
      likedPeopleImg,
      editImg,
      deleteImg,
      archiveImg,
      likedImg,
      areCommentsOpen: false,
      reportImg,
      followImg,
      followedImg,
      followImgDisabled,
      infoCircleIcon,

      showModal: false,
      showText: false,
      seeMore: false,
      haveSeeMoreButton: false,
      postTypes: {
        10: {
          title: 'Course',
          style: 'course',
        },
        20: {
          title: 'Job',
          style: 'job',
        },
        30: {
          title: 'Marketplace',
          style: 'marketplace',
        },
        40: {
          title: 'Videos',
          style: 'video',
        },
        50: {
          title: 'Sound archive',
          style: 'sound',
        },
      },

    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    isBusinessProfile() {
      return this.user.active_business_profile.id
    },
    likedCurrentUser() {
      if (this.isBusinessProfile) {
        return this.item.likes.users.includes(this.user.active_business_profile.name)
      }
      return this.item.likes.users.includes(this.user.name)
    },
    getLogo() {
      return this.item.owner.image || this.avatarPlaceholder
    },
    isOwner() {
      if (this.representACompany && this.user.active_business_profile.role !== 'viewer') return this.item.owner.slug === this.user.active_business_profile.slug
      return this.item.owner.slug === this.user.slug
    },
    representACompany() {
      return 'id' in this.user.active_business_profile
    },
    canInteract() {
      if (this.user.active_business_profile.slug === this.item.owner.slug) return true
      if (this.user.slug === this.item.owner.slug) return true
      if (this.user.user_info.approved_hrp === 2) return true
      return false
    },
    representCompanyAdminOrEditor() {
      return this.user.active_business_profile.role === 'owner' || this.user.active_business_profile.role === 'editor' || this.user.active_business_profile.role === 'admin'
    },
    isCommentingAllowed() {
      if (this.isViewer) return false
      if (!('is_comments_allowed' in this.item) || this.item.is_comments_allowed) return true
      return false
    },
    isViewer() {
      return this.representACompany && this.user.active_business_profile.role === 'viewer'
    },
  },
  methods: {
    openComments() {
      if (!this.isCommentingAllowed) return
      this.areCommentsOpen = !this.areCommentsOpen
    },
    showNoHPRConfirmedMessage() {
      EventBus.$emit(`showNoHPRConfirmedMessage-post-${this.item.slug}`)
    },
    showReportModal() {
      if (!this.canInteract) {
        this.showNoHPRConfirmedMessage()
      } else {
        this.showModal = true
      }
    },
    deletePost() {
      this.$store.dispatch('posts/deletePostById', { slug: this.item.slug, owner: this.item.owner.type }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Deleted'),
            icon: 'ThumbsUpIcon',
            variant: 'success',
          },
        })
        this.$emit('fetch-posts')
      })
    },
    archivePost() {
      this.$store.dispatch('posts/archivePostById', { slug: this.item.slug, owner: this.item.owner.type }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Archived'),
            icon: 'ThumbsUpIcon',
            variant: 'success',
          },
        })
        this.$emit('fetch-posts')
      })
    },
    async like() {
      if (!this.canInteract) {
        this.showNoHPRConfirmedMessage()
        return
      }
      if (this.isViewer) return
      try {
        const res = await axiosIns.post(`/posts/${this.item.slug}/like`)

        if (res.status === 200) {
          this.$emit('update-post', this.item.slug)
        }
      } catch (e) {
        this.$store.dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async followPost() {
      if (this.representACompany) return
      try {
        const res = await axiosIns.post(`/posts/${this.item.slug}/follow`)

        if (res.status === 200) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          })
          this.$emit('update-post', this.item.slug)
        }
      } catch (e) {
        this.$store.dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async followUserOrCompany() {
      let url = '/user/follow'

      if (this.item.owner.type === 'partner_company') {
        url = '/partner-company-follow'
      }

      try {
        const res = await axiosIns.post(`${url}/${this.item.owner.slug}`)

        if (res.status === 200) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          })
          this.$emit('update-post', this.item.slug)
        }
      } catch (e) {
        this.$store.dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    goToOwnerPage() {
      if (this.item.owner.type === 'partner_company') {
        this.$router.push(`/profile_company/${this.item.owner.slug}`)
      } else {
        this.$router.push(`/profile/${this.item.owner.slug}`)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.e-post {
  background-color: white;
  padding: 1.3rem 1.3rem;
  border-radius: 6px;
  margin-bottom: 25px;
}

.e-post-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .e-post-author {
    &__title {
      padding-left: 15px;
    }
    &__vertical-line {
      padding-left: 20px;
      padding-right: 20px;
    }
    .avatar-logo {
      border-radius: 50px;
      width: 42px;
      height: 42px;
    }
  }
  .e-post-action {
    display: flex;
    align-items: center;
    img {
      padding-right: 8px;
      padding-bottom: 5px;
    }
    &:hover {
      cursor: pointer;
    }
  }
}

.e-post-content {
  padding-top: 25px;

  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    color: #0f2f50;
    padding-bottom: 10px;
  }
  &__description {
    color: #6e6b7b;
    position: relative;
  }

  .text {
    position: relative;
    overflow: hidden;
    padding-bottom: 20px;
  }
  &__image {
    img {
      width: 100%;
      border-radius: 10px;
    }
  }
  &__vertical-line {
    width: 100%;
  }
  &__footer {
    padding-top: 10px;
  }
  &__footer_row1 {
    display: flex;
    justify-content: space-between;

    .liked-block {
      display: flex;
      span {
        padding-left: 5px;
      }
    }
  }
  &__footer_row2 {
    display: flex;
    justify-content: space-around;
    padding-top: 5px;

    .liked {
      color: #2986e8;
    }

    > div {
      img {
        padding-right: 5px;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.post-report {
  padding-left: 10px;
  &:hover {
    cursor: pointer;
  }
}

.e-post-author-right {
  display: flex;
  align-items: center;
}

.followed {
  color: #2986E8;
}
</style>
