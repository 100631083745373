<template>
  <div class="w-100 d-flex comment-card">
    <div @click="goToUserProfile">
      <b-avatar
        size="32"
        :src="avatar"
      />
    </div>
    <div>
      <div class="comment-body">
        <h5 class="font-weight-bold">
          {{ comment.owner.name }}
        </h5>
        <h5 class="mb-0 text-dark">
          {{ comment.text }}
        </h5>
      </div>
      <h6 class="text-muted">
        {{ comment.created_at | transformCreateDate }}
      </h6>
    </div>
  </div>
</template>

<script>
import moment from 'moment/min/moment-with-locales'

import { BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
  },
  filters: {
    transformCreateDate(value) {
      const today = moment()
      const date = moment(value)
      if (today.diff(date, 'd') >= 1) {
        return date.format('DD MMMM YYYY, hh:mm')
      }
      return date.fromNow()
    },
  },
  props: {
    comment: {
      type: Object,
    },
    representACompany: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    avatar() {
      return this.comment.owner.profile_avatar ? this.comment.owner.profile_avatar : require('@/assets/images/pages/profile/user-default-avatar.svg')
    },
  },
  methods: {
    goToUserProfile() {
      if (this.representACompany) return
      this.$router.push(`/profile/${this.comment.owner.slug}`)
    },
  },
}
</script>
