<template>
  <div class="wrapper-feed">
    <div class="wrapper-feed__info">
      <no-access-info-card v-if="showNoAccessInfoCard" />
      <div
        v-else
        class="wrapper-feed__info__container"
      >
        <div class="search-posts">
          <div class="position-relative d-flex align-items-center justify-content-center account-image-container">
            <img
              :src="currentAvatar ? currentAvatar : require('@/assets/images/pages/profile/user-default-avatar.svg')"
              alt="User logo"
              width="34"
              height="34"
              class="account-image"
            >
          </div>
          <button @click="() => openModal(null)">
            Share your thoughts here...
          </button>
        </div>
        <div
          v-if="!feeds || !feeds.length"
          class="d-flex align-items-center justify-content-center"
        >
          <img
            src="@/assets/images/pages/course/empty-list.svg"
            alt="Buypass"
            class="d-inline-block mt-5"
          >
        </div>
        <div v-else>
          <Post
            v-for="item in feeds"
            :key="item.id"
            :item="item"
            @fetch-posts="getFeeds"
            @edit-post="openModal"
            @update-post="fetchPost"
          />
          <div
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-if="pagination && (pagination.total > pagination.per_page)"
              v-model="pageNumber"
              :per-page="pagination.per_page"
              :total-rows="pagination.total"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </div>
    </div>
    <div class="wrapper-feed__sidebar">
      <div class="feed-search">
        <b-input-group class="input-group-merge">
          <b-input-group-prepend
            class="no-border"
            is-text
          >
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>

          <b-form-input
            v-model="search"
            type="search"
            class="no-border"
            :placeholder="$t('Search...')"
            @click="checkHPR"
          />
        </b-input-group>
        <e-dropdown
          :items="profiles"
          type="single"
        />
      </div>
    </div>

    <PostManage
      v-if="showModal"
      :post="feed"
      :entity="entity"
      @hide="showModal = false"
      @add-post="addPost"
      @update-post="updateFeed"
    />
    <NoHPRConfirmedMessage id="Feed" />
  </div>
</template>

<script>
import Post from '@/components/profile-company/Post.vue'
import {
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
} from 'bootstrap-vue'
import axiosIns from '@/libs/axios'
import { mapGetters } from 'vuex'
import PostManage from '@/components/modal/post-manage'
import userPlaceholder from '@/assets/images/pages/user-placeholder.svg'
import circle from '@/assets/images/pages/circle.svg'
import reloading from '@/assets/images/pages/profile-company/reloading.svg'
import line from '@/assets/images/pages/Line.svg'
import { debounce } from 'vue-debounce'
import eDropdown from '@/elements/e-dropdown'
import NoAccessInfoCard from '@/components/NoAccessInfoCard.vue'
import NoHPRConfirmedMessage from '@/components/modal/NoHPRComfirmedMessage.vue'

export default {
  components: {
    Post,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    PostManage,
    eDropdown,
    NoAccessInfoCard,
    NoHPRConfirmedMessage,
  },
  data() {
    return {
      userPlaceholder,
      circle,
      line,
      reloading,

      feeds: [],
      feed: null,
      pagination: null,
      showModal: false,
      entity: '',
      profiles: [],
      isUserProfileActive: true,
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    pageNumber: {
      get() { return this.pagination.current_page },
      set(pageNumber) {
        this.getFeeds(pageNumber)
        window.scrollTo(0, 0)
      },
    },
    currentAvatar() {
      if (this.user.active_business_profile.image || this.user.active_business_profile.image === null) {
        return this.user.active_business_profile.image
      }
      return this.user.profile_avatar
    },
    search: {
      get() { return '' },
      // eslint-disable-next-line
      set: debounce(function(search) {
        this.searchProfiles(search)
      }, 500),
    },
    haveCompanyProfile() {
      return this.user.companies.data.length > 0
    },
    showNoAccessInfoCard() {
      return this.user.user_info.approved_hrp === 1
    },
  },
  created() {
    this.getFeeds()
  },
  methods: {
    checkHPR(event) {
      if (this.user.user_info.approved_hrp === 1) {
        event.target.blur()
        this.showNoHPRConfirmedMessage()
      }
    },
    showNoHPRConfirmedMessage() {
      EventBus.$emit('showNoHPRConfirmedMessage-Feed')
    },
    goToProfile(item) {
      let url = 'profile_company'
      if (item.type === 'user') url = 'profile'
      this.$router.push(`${url}/${item.slug}`)
    },
    async addPost(slug) {
      this.$store.dispatch('posts/fetchPostById', slug).then(post => {
        this.feeds.unshift(post)
      })
    },
    async fetchPost(slug) {
      this.$store.dispatch('posts/fetchPostById', slug).then(post => {
        const array = [...this.feeds]
        const index = this.feeds.findIndex(el => el.slug === slug)
        array[index] = post
        this.feeds = [...array]
      })
    },
    async getFeeds(pageNumber = 1) {
      try {
        const res = await axiosIns.get(`timeline?page=${pageNumber}`)

        if (res.status === 200) {
          this.feeds = res.data.companyPosts.concat(
            res.data.generalPosts.data,
          )

          this.pagination = res.data.generalPosts.meta.pagination
        }
      } catch (e) {
        this.$store.dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async updateFeed(slug) {
      this.$store.dispatch('posts/fetchPostById', slug).then(post => {
        const array = [...this.feeds]
        const index = array.findIndex(el => el.slug === slug)
        array[index] = post
        this.feeds = [...array]
      })
    },
    openModal(feed) {
      this.feed = feed
      this.entity = 'user-posts'

      if (!this.isUserProfileActive || (this.feed && this.feed.owner.type === 'company')) {
        this.entity = 'partner-company-posts'
      }

      this.showModal = true
    },
    async searchProfiles(val) {
      try {
        const res = await axiosIns.get(`profile/search?name=${val}`)

        if (res.status === 200) {
          this.profiles = res.data.data
        }
      } catch (e) {
        this.$store.dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/abstracts/_responsive.scss";

  .wrapper-feed {
    display: flex;
    width: 100%;
    @include media-q-md {
      flex-direction: column-reverse;
    }

    &__info {
      width: 70%;
      @include media-q-md {
        width: 100%;
      }
      &__container {
        max-width: 660px;
        margin: 0 auto;
      }
    }

    &__sidebar {
      width: 30%;
      padding-left: 20px;
      @include media-q-md {
        width: 100%;
        padding-left: 0px;
        padding-bottom: 25px;
      }
    }
  }
  .account-image-container {
    img:last-child {
      cursor: pointer;
      transition: transform .5s, width .5s;

      &:active {
        animation-name: roteteAnimation;
        animation-duration: .5s;
        animation-iteration-count: 1;
        animation-timing-function: ease;
      }
    }
  }

  @keyframes roteteAnimation {
  from {
    transform: rotate(0deg) scale(1);
  }
  to {
    transform: rotate(-360deg) scale(1.1);
  }
}

  .search-posts {
    display: flex;
    align-items: center;
    background-color: white;
    margin-bottom: 25px;
    border-radius: 6px;
    padding: 15px;

    img.account-image {
      border-radius: 50px;
    }

    button {
      border: 1px solid #E0E0E0;
      text-align: left;
      color: #90959A;
      padding-left: 15px;
      border-radius: 5px;
      background-color: #F8F8F8;
      width: 100%;
      height: 40px;
      margin-left: 10px;
    }
  }
</style>
