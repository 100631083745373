<template>
  <div class="wrapper-post-manage">
    <b-modal
      id="post-manage"
      v-model="isShowModal"
      no-enforce-focus
      hide-footer
      @hide="$emit('hide')"
    >
      <div @focusin.stop>
        <validation-observer
          ref="postManage"
          v-slot="{ invalid }"
        >
          <b-form class="post-form">
            <h3 class="post-form__title">
              {{ post ? 'Update' : 'Create' }} post
            </h3>

            <!-- title -->
            <b-form-group
              :label="$t('Post title')"
              label-for="Post title"
            >
              <validation-provider
                #default="{ errors }"
                name="Post title"
                rules="required"
              >
                <b-form-input
                  v-model="form.title"
                  name="Post title"
                  :placeholder="$t('Post title')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Description -->
            <b-form-group
              :label="$t('Description')"
            >
              <validation-provider
                #default="{ errors }"
                name="Description"
                rules="required"
              >
                <TinyMCE
                  v-model="form.text"
                  :menubar="false"
                  :toolbar1="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Image -->
            <b-form-group
              :label="$t('Post image placeholder')"
            >
              <div
                class="
                  file-input
                  d-flex
                  flex-column
                  align-items-center
                  justify-content-center
                  w-100
                "
              >
                <img
                  :src="fileDropImage"
                  alt="test"
                >
                <h6 class="font-weight-bolder text-center px-1 pt-1">
                  {{ $t("Drop an image here or") }}
                  <span class="text-primary cursor-pointer">
                    {{ $t("click") }}
                  </span>
                  {{ $t("to upload") }}
                </h6>
                <b-form-file
                  id="files"
                  v-model="form.image"
                  name="file"
                  class="drop-input"
                  @input="(file) => uploadImg(file)"
                />
              </div>
              <!-- Placeholder for images -->
              <div class="wrapper-cropper">
                <div
                  v-if="cropperImg"
                  class="col-10"
                >
                  <cropper
                    :img="cropperImg"
                    @returnImage="saveCompanyImage"
                  />

                </div>
                <div
                  v-if="image"
                  class="col-3 px-0 py-1"
                >
                  <img
                    :src="image"
                    alt="images post"
                    class="w-100"
                  >
                </div>
              </div>
            </b-form-group>

            <!-- Submit -->
            <div class="col-12 text-center">
              <b-button
                variant="primary"
                :disabled="invalid"
                @click="submit"
              >
                {{ post ? 'Update' : 'Post' }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import TinyMCE from '@/components/editor/TinyMCE.vue'
import fileDropImage from '@/assets/images/pages/marketplace/file-drop-image.svg'
import Cropper from '@/components/cropper/Cropper.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapGetters } from 'vuex'
import axiosIns from '@/libs/axios'
import {
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BFormFile,
  BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    TinyMCE,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormFile,
    BButton,
    Cropper,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    post: {
      type: Object,
      required: false,
      default: () => null,
    },
    entity: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fileDropImage,

      isShowModal: true,
      form: {
        title: null,
        text: null,
        image: null,
      },
      image: null,
      cropperImg: null,
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  created() {
    if (this.post) {
      this.form.title = this.post.title
      setTimeout(() => {
        this.form.text = this.post.text
      }, 1000)
    }
  },
  methods: {
    uploadImg(file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = () => {
        this.cropperImg = reader.result
      }
    },
    saveCompanyImage(val) {
      this.image = val
    },
    async submit() {
      const isValid = await this.$refs.postManage.validate()

      if (isValid) {
        try {
          const formData = new FormData()

          formData.append('title', this.form.title)
          formData.append('text', this.form.text)
          if (this.form.image) {
            formData.append('image', this.form.image)
          }

          let url = 'posts'
          let message = 'created'

          if (this.post) {
            url = `/posts/${this.post.slug}/edit`
            message = 'updated'
          }

          const res = await axiosIns.post(url, formData)

          if (res.status === 200) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Your post has been successfully ${message}!`,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })

            this.$emit(
              this.post ? 'update-post' : 'add-post',
              res.data.slug,
            )

            this.form.title = null
            this.form.text = null
            this.form.image = null
            this.image = null
            this.cropperImg = null

            this.$emit('hide')
          }
        } catch (e) {
          this.$store.dispatch('errorHandler', e, { root: true })
          throw e
        }
      }
    },
  },
}
</script>
