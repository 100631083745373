<template>
  <div class="e-dropdown w-100 px-1">
    <ul>
      <li
        v-for="(item) in items"
        :key="item.slug"
        :class="{'active': isActiveItem(item.slug)}"
        @click.stop="selectItem(item)"
      >
        <div class="dropdown-info">
          <div class="dropdown-info__logo">
            <img
              :src="item.image || userPlaceholder"
              alt="User placeholder"
            >
          </div>
          <div class="dropdown-contact">
            <div class="dropdown-contact__name">
              <span>{{ item.name }}</span>
            </div>
            <div>
              <span class="dropdown-contact__followers">Followers: {{ item.followers_count }}</span>
            </div>
          </div>
          <b-form-checkbox
            v-if="type === 'multiple'"
            :ref="`checkbox-${item.slug}`"
            v-model="activeItems"
            :value="item.slug"
            class="ml-auto"
          />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>

import userPlaceholder from '@/assets/images/pages/user-placeholder.svg'
import { BFormCheckbox } from 'bootstrap-vue'

export default {
  components: {
    BFormCheckbox,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      userPlaceholder,
      activeItems: [],
    }
  },
  methods: {
    selectItem(item) {
      if (this.type === 'single') {
        let url = 'profile_company'
        if (item.type === 'user') url = 'profile'
        this.$router.push(`${url}/${item.slug}`)
      } else if (this.type === 'multiple') {
        const index = this.activeItems.findIndex(i => i === item.slug)
        if (index === -1) { // add
          this.activeItems.push(item.slug)
        } else { // delete
          this.activeItems.splice(index, 1)
        }
      }
    },
    isActiveItem(slug) {
      return this.activeItems.includes(slug)
    },
  },
}
</script>
